import logo from './images/StartKarobar-removebg-preview.png';
import './App.css';

function App(){

  
  return (<>
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          <b>StartKarobar!!!</b> We are coming soon...
        </p>
          Contact Us at 0300-8261403
      </header>
    </div>

  </>);
}

export default App;
